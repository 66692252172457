import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useDispatch, useSelector } from 'react-redux';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { themeProps } from '../styles/theme';
import { setSelectedEndDate, setSelectedStartDate } from '../state/actions';
import { getSelectedEndDate, getSelectedStartDate } from '../state/selectors';
import useStyles from '../styles';
import { getEndOfDay, getStartOfDay } from '../utils/functions';
import SelectHoursPicker from './SelectHoursPicker';

dayjs.extend(localizedFormat);

export enum JumpOptions {
  Week = 'Week',
  Month = 'Month',
  Sixmonth = '6 month',
  Year = 'Year',
  Custom = 'Custom',
}

interface JumpSize {
  unit: JumpUnit;
  number: number;
}

enum JumpUnit {
  Day,
  Month,
}

export enum MetricOptions {
  Raw = 'Raw',
  Hourly = 'Hourly',
  Daily = 'Daily',
}

interface DateRangePickerProps {
  allowLongTime?: boolean;
  defaultJump?: JumpOptions;
  metricSelection?: MetricOptions;
  setMetricSelection?: React.Dispatch<React.SetStateAction<MetricOptions>>;
}

export const jumpTimeRange = (startDate: Date, endDate: Date, jumpSize: JumpSize) => {
  if (jumpSize.number === 0) {
    return { newStart: startDate, newEnd: endDate };
  }

  let newStartDay = dayjs(startDate);
  let newEndDay = dayjs(endDate);

  if (jumpSize.unit === JumpUnit.Day) {
    // We are doing a shorter range and want to align to week start
    const weekOffset = dayjs(startDate).day();

    if (weekOffset === 0 || Math.abs(jumpSize.number) < 7) {
      // We are aligned to the end of the week or jumping very small size
      newStartDay = dayjs(startDate).add(jumpSize.number, 'day');
      newEndDay = dayjs(endDate).add(jumpSize.number, 'day');
    } else {
      let jumpDays = jumpSize.number - weekOffset;
      if (jumpSize.number < 0) {
        jumpDays = jumpSize.number + 7 - weekOffset;
      }
      newStartDay = dayjs(startDate).add(jumpDays, 'day');
      newEndDay = dayjs(endDate).add(jumpDays, 'day');
    }
  } else {
    // We are jumping in number of months
    const monthOffset = dayjs(startDate).date() - 1;
    if (monthOffset === 0) {
      newStartDay = dayjs(startDate).add(jumpSize.number, 'month');
      newEndDay = dayjs(endDate).add(jumpSize.number, 'month').endOf('month');
    } else {
      let jumpMonths = 0;
      if (jumpSize.number < 0) {
        // Going back offset counts as going to going back one month
        jumpMonths = jumpSize.number + 1; // Jump number is negative so we are making jump smaller
        // If jumping to different year align to start of year instead
        jumpMonths = -1 * Math.min(Math.abs(jumpMonths), dayjs(startDate).month());
      } else {
        jumpMonths = jumpSize.number;
        // If jumping to different year align to start of year instead
        jumpMonths = Math.min(jumpMonths, 11 - dayjs(startDate).month());
      }
      // Go to start of month and then move the number of months we are interested in
      newStartDay = dayjs(startDate).subtract(monthOffset, 'day').add(jumpMonths, 'month');
      newEndDay = dayjs(endDate).subtract(monthOffset, 'day').add(jumpMonths, 'month');
    }
  }

  // Don't go beyond today
  if (jumpSize.number > 0 && newEndDay.isAfter(dayjs())) {
    const dayOffset = dayjs().diff(newEndDay, 'day');
    newStartDay = dayjs(newStartDay).add(dayOffset, 'day');
    newEndDay = dayjs(newEndDay).add(dayOffset, 'day');
  }

  return { newStart: newStartDay.toDate(), newEnd: newEndDay.toDate() };
};

function compareDates(d1: Date, d2: Date): number {
  const d1t = d1.getTime();
  const d2t = d2.getTime();

  if (d1t < d2t) {
    return -1;
  }

  if (d1t > d2t) {
    return 1;
  }

  return 0;
}

function formatDateToValue(d: Date): string {
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return `${d.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
}

export function DatePicker(props: { startDate: Date; endDate: Date }): JSX.Element {
  const {startDate, endDate} = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const setStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sd = new Date(e.target.value);
    if (!Number.isNaN(sd.getTime())) {
      const d = getStartOfDay(sd);
      // Check that the date typed is valid (ie not after today)
      if (compareDates(d, new Date()) <= 0) {
        dispatch(setSelectedStartDate(d));
      }
    }
  };

  const setEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const d = new Date(e.target.value);
    if (!Number.isNaN(d.getTime())) {
      dispatch(setSelectedEndDate(getEndOfDay(d)));
    }
  };

  return (
    <div className={classes.dateRangePicker}>
      <input
        type="date"
        onChange={setStartDate}
        max={formatDateToValue(endDate ?? new Date())}
        value={formatDateToValue(startDate)}
      />
      <KeyboardArrowRightIcon />
      <input
        type="date"
        onChange={setEndDate}
        min={formatDateToValue(startDate ?? new Date())}
        max={formatDateToValue(new Date())}
        value={formatDateToValue(endDate)}
      />
    </div>
  );
}

function DateRangePicker({
  allowLongTime,
  defaultJump,
  metricSelection,
  setMetricSelection,
}: DateRangePickerProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [jumpSelection, setJumpSelection] = useState<JumpOptions | null>(null);
  const [jumpOptionsAnchor, setJumpOptionsAnchor] = useState<null | HTMLElement>(null);
  const [jumpSize, setJumpSize] = useState<JumpSize>({ unit: JumpUnit.Day, number: 7 });
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  const handleDefaultJumpSelection = () => {
    setJumpSelection(JumpOptions.Week);
    setJumpSize({ unit: JumpUnit.Day, number: 7 });
    dispatch(
      setSelectedStartDate(
        dayjs(new Date(endDate)).subtract(1, 'week').add(1, 'day').startOf('day').toDate()
      )
    );
  };

  const handleJumpSelection = (selection: JumpOptions) => {
    setJumpOptionsAnchor(null);
    setJumpSelection(selection);
    // Change the jump size and date range to match selection
    switch (selection) {
      case JumpOptions.Week: {
        setJumpSize({ unit: JumpUnit.Day, number: 7 });
        dispatch(
          setSelectedStartDate(
            dayjs(endDate).subtract(1, 'week').add(1, 'day').startOf('day').toDate()
          )
        );
        break;
      }
      case JumpOptions.Month: {
        setJumpSize({ unit: JumpUnit.Month, number: 1 });
        dispatch(
          setSelectedStartDate(
            dayjs(endDate).subtract(1, 'month').add(1, 'day').startOf('day').toDate()
          )
        );
        break;
      }
      case JumpOptions.Sixmonth: {
        setJumpSize({ unit: JumpUnit.Month, number: 6 });
        dispatch(
          setSelectedStartDate(
            dayjs(endDate).subtract(6, 'month').add(1, 'day').startOf('day').toDate()
          )
        );
        break;
      }
      case JumpOptions.Year: {
        setJumpSize({ unit: JumpUnit.Month, number: 12 });
        dispatch(
          setSelectedStartDate(
            dayjs(endDate).subtract(12, 'month').add(1, 'day').startOf('day').toDate()
          )
        );
        break;
      }
      default: {
        // For Custom option
        // Do nothing as don't need jump size or want to change date range
        break;
      }
    }
  };

  function changeJumpIfNeeded(longAllowed?: boolean) {
    const daydiff = dayjs(endDate).diff(dayjs(startDate), 'day', true);
    if (daydiff > 6.99 && daydiff <= 7) handleDefaultJumpSelection();
    else {
      const monthDiff = dayjs(endDate).diff(dayjs(startDate), 'month', true);
      if (monthDiff > 0.99 && monthDiff <= 1) {
        setJumpSelection(JumpOptions.Month);
        setJumpSize({ unit: JumpUnit.Month, number: 1 });
      } else if (monthDiff > 11.9 && monthDiff <= 12.1) {
        // 6 month and year range only allowed in calendar view
        // switch to week if range is 6 month or 1 year in compare page
        if (longAllowed) {
          setJumpSelection(JumpOptions.Year);
          setJumpSize({ unit: JumpUnit.Month, number: 12 });
        } else handleDefaultJumpSelection();
      } else if (monthDiff > 5.9 && monthDiff <= 6.1) {
        if (longAllowed) {
          setJumpSelection(JumpOptions.Sixmonth);
          setJumpSize({ unit: JumpUnit.Month, number: 6 });
        } else handleDefaultJumpSelection();
      } else {
        setJumpSelection(JumpOptions.Custom);
      }
    }
  }

  if (!jumpSelection) {
    if (defaultJump) handleJumpSelection(defaultJump);
    else changeJumpIfNeeded(allowLongTime);
  }

  const openJumpOptions = Boolean(jumpOptionsAnchor);

  const onPrevTimeRange = () => {
    // Jumping backwards in time so negative jump number
    const { newStart, newEnd } = jumpTimeRange(startDate, endDate, {
      ...jumpSize,
      number: jumpSize.number * -1,
    });
    if (newStart) dispatch(setSelectedStartDate(newStart));
    if (newEnd) dispatch(setSelectedEndDate(newEnd));
  };

  const onNextTimeRange = () => {
    const { newStart, newEnd } = jumpTimeRange(startDate, endDate, jumpSize);
    if (newStart) dispatch(setSelectedStartDate(newStart));
    if (newEnd) dispatch(setSelectedEndDate(newEnd));
  };

  const handleMetricChange = (event: React.MouseEvent<HTMLElement>, newMetric: MetricOptions) => {
    if (newMetric === MetricOptions.Raw) changeJumpIfNeeded(false);
    if (newMetric && setMetricSelection) setMetricSelection(newMetric);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { lg: 'start', xs: 'space-between' },
          width: { lg: '33%', xs: '100%' },
        }}
      >
        <Menu
          id="settings-menu"
          anchorEl={jumpOptionsAnchor}
          open={openJumpOptions}
          onClose={() => setJumpOptionsAnchor(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuList sx={{ padding: 0 }}>
            <MenuItem onClick={() => handleJumpSelection(JumpOptions.Week)}>Week</MenuItem>
            <MenuItem onClick={() => handleJumpSelection(JumpOptions.Month)}>Month</MenuItem>
            {allowLongTime && (
              <Box>
                <MenuItem onClick={() => handleJumpSelection(JumpOptions.Sixmonth)}>
                  6 Month
                </MenuItem>
                <MenuItem onClick={() => handleJumpSelection(JumpOptions.Year)}>Year</MenuItem>
              </Box>
            )}
            <MenuItem onClick={() => handleJumpSelection(JumpOptions.Custom)}>Custom Date</MenuItem>
          </MenuList>
        </Menu>
        <Button
          variant={themeProps.btnVariant.text}
          onClick={(event) => setJumpOptionsAnchor(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          startIcon={<CalendarMonthIcon />}
          style={{ fontSize: themeProps.textSize.h6 }}
        >
          {jumpSelection}
        </Button>
        <SelectHoursPicker />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: { lg: '33%', xs: '100%' },
          justifyContent: { lg: 'center', xs: 'center' },
        }}
      >
        {metricSelection && setMetricSelection && (
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={metricSelection}
            exclusive
            onChange={handleMetricChange}
            aria-label="data-metric-source"
          >
            <ToggleButton sx={{ textTransform: 'inherit' }} value={MetricOptions.Raw}>
              {MetricOptions.Raw}
            </ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value={MetricOptions.Hourly}>
              {MetricOptions.Hourly}
            </ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value={MetricOptions.Daily}>
              {MetricOptions.Daily}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: { lg: '33%', xs: '100%' },
          justifyContent: { lg: 'end', xs: 'center' },
        }}
      >
        {jumpSelection === JumpOptions.Custom ? (
          <DatePicker startDate={startDate} endDate={endDate} />
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography variant="h6" style={{ alignSelf: 'center' }}>
              {`${dayjs(startDate).format('ll')}
            - ${dayjs(endDate).format('ll')} `}
            </Typography>

            <IconButton
              onClick={() => onPrevTimeRange()}
              style={{
                background: theme.palette.primary.contrastText,
                margin: '5px',
                padding: '5px',
              }}
            >
              <Tooltip title="Previous time period">
                <KeyboardArrowLeftIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={() => onNextTimeRange()}
              disabled={dayjs(endDate).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')}
              style={{
                background: theme.palette.primary.contrastText,
                margin: '5px',
                padding: '5px',
              }}
            >
              <Tooltip title="Next time period">
                <KeyboardArrowRightIcon />
              </Tooltip>
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

DateRangePicker.defaultProps = {
  allowLongTime: false,
  defaultJump: null,
  metricSelection: null,
  setMetricSelection: null,
};

export default DateRangePicker;
