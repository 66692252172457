import { createTheme, darken, lighten } from '@mui/material/styles';
import { ThemeMode } from '../state/types';

// commenting out to avoid typescript log err for not being used, but we might need them in future
// const oldBrandColors = {
//   spearmint: '#38b4a0',
//   coral: '#ff6464',
//   buttercup: '#f7d634',
//   apricot: '#ffa930',
//   tomato: '#fc5320',
//   apple: '#73b72b',
//   sky: '#42a5e8',
//   pearl: '#FAFAFA',
//   amber: '#F5A623',
//   opacity: {
//     blue10: '#E6EFF6',
//     blue50: '#80aed4',
//     blue60: '#669dcb',
//   },
// };

type ButtonVariantType = 'text' | 'outlined' | 'contained';

export enum ButtonVariant {
  default = 'outlined',
  secondary = 'contained',
  text = 'text',
}

export const themeProps = {
  colors: {
    lightBlue: '#7780B1',
    cobalt: '#005ca9',
    green: '#10CDAE',
    happyGreen: '#48CF7E',
    alertYellow: '#FFC931',
    warningRed: '#FF6F66',
    infoBlue: '#3DA1FF',
    baseWhite: '#F1F2FA',
    black: '#333',
    white: '#FFFFFF',
    violet: '#5f50bc',
    darkGrey: '#1c1d22', // 1000', for tint colors, opacity to be 16%
    grey: '#2e3038', // 800
    mediumGrey: '#40434f', // 600,
    lightGrey: '#65697c', // 400,
    defaultColor: '#2e3038', // grey for now, should be main dark color for marker
    summaryLineDark: '#1a3232', // line color for dark theme ring plot in active summary card
    summaryLineLight: '#d7eff2', // line color for light theme ring plot in active summary card
    activeSummaryCard: '#10CDAE1d', // transparent green background for active summary card
    // additional colors for report template
    spearmint: '#38b4a0',
    lightRed: '#fe9393',
    lightGreen: '#74cbbd',
  },
  fontFamily: {
    body: 'Montserrat',
    titles: 'Poppins',
  },
  textSize: {
    h1: '56px',
    h2: '40px',
    h3: '32px',
    h4: '24px',
    h5: '20px',
    h6: '16px',
    default: '14px',
    small: '12px',
  },
  lineHeight: {
    h1: '56px',
    h2: '40px',
    h3: '32px',
    h4: '24px',
    base: '20px',
  },
  fontWeight: {
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
  spacers: {
    spacer1: '4px',
    spacer2: '8px',
    spacer3: '12px',
    spacer4: '16px',
    spacer5: '32px',
    spacer6: '48px',
    spacer7: '72px',
  },
  btnVariant: {
    default: 'outlined' as ButtonVariantType,
    secondary: 'contained' as ButtonVariantType,
    text: 'text' as ButtonVariantType,
  },
};

// purple palette
// const darkPalette = {
//   dark: '#141D43',
//   main: '#282E57',
//   light: '#3C3D61',
//   text: '#ffffff',
//   contrast: '#7780B1',
// };

// grey palette
const darkPalette = {
  dark: themeProps.colors.darkGrey,
  main: themeProps.colors.grey,
  light: themeProps.colors.mediumGrey,
  text: themeProps.colors.white,
  contrast: themeProps.colors.lightGrey,
};

const lightPalette = {
  dark: themeProps.colors.baseWhite,
  main: themeProps.colors.white,
  light: lighten(themeProps.colors.baseWhite, 0.5),
  text: themeProps.colors.grey,
  contrast: lighten(themeProps.colors.lightGrey, 0.5),
};

const getColorPalette = (mode: ThemeMode) => {
  let colorPalette = darkPalette;
  if (mode === ThemeMode.light) colorPalette = lightPalette;
  return colorPalette;
};

export const getTheme = (mode: ThemeMode) => {
  const colorPalette = getColorPalette(mode);
  const darkColor = colorPalette.dark; // for nav and pills bg
  const mainColor = colorPalette.main; // for card, list and paper bg
  const lightColor = colorPalette.light; // for main bg, some cards to match bodyBg: for instance in compare page
  const textColor = colorPalette.text; // for general text
  const contrastColor = colorPalette.contrast; // for hovered list, menu items bg

  return createTheme({
    palette: {
      primary: {
        dark: darkColor,
        main: mainColor,
        light: lightColor,
        contrastText: contrastColor,
      },
      secondary: { main: textColor },
      error: { main: themeProps.colors.warningRed },
      warning: { main: themeProps.colors.alertYellow },
      info: { main: themeProps.colors.infoBlue },
      success: { main: themeProps.colors.green },
      text: { primary: textColor, secondary: mainColor },
    },
    typography: {
      fontFamily: [themeProps.fontFamily.body, 'sans-serif'].join(','),
      fontWeightRegular: themeProps.fontWeight.normal,
      fontWeightLight: themeProps.fontWeight.normal,
      fontWeightMedium: themeProps.fontWeight.semiBold,
      fontWeightBold: themeProps.fontWeight.bold,
      h1: {
        fontFamily: [themeProps.fontFamily.titles, 'sans-serif'].join(','),
        fontSize: themeProps.textSize.h1,
        fontWeight: themeProps.fontWeight.bold,
        lineHeight: themeProps.lineHeight.h1,
        color: textColor,
      },
      h2: {
        fontFamily: [themeProps.fontFamily.titles, 'sans-serif'].join(','),
        fontSize: themeProps.textSize.h2,
        fontWeight: themeProps.fontWeight.semiBold,
        lineHeight: themeProps.lineHeight.h2,
        color: textColor,
      },
      h3: {
        fontFamily: [themeProps.fontFamily.titles, 'sans-serif'].join(','),
        fontSize: themeProps.textSize.h3,
        fontWeight: themeProps.fontWeight.semiBold,
        lineHeight: themeProps.lineHeight.h3,
        color: textColor,
      },
      h4: {
        fontFamily: [themeProps.fontFamily.titles, 'sans-serif'].join(','),
        fontSize: themeProps.textSize.h4,
        fontWeight: themeProps.fontWeight.semiBold,
        lineHeight: themeProps.lineHeight.h4,
        color: textColor,
      },
      h5: {
        fontFamily: [themeProps.fontFamily.titles, 'sans-serif'].join(','),
        fontSize: themeProps.textSize.h5,
        fontWeight: themeProps.fontWeight.semiBold,
        lineHeight: themeProps.lineHeight.base,
        color: textColor,
      },
      h6: {
        fontFamily: [themeProps.fontFamily.titles, 'sans-serif'].join(','),
        fontSize: themeProps.textSize.h6,
        fontWeight: themeProps.fontWeight.semiBold,
        lineHeight: themeProps.lineHeight.base,
        color: textColor,
      },
      subtitle1: {
        fontFamily: [themeProps.fontFamily.body, 'sans-serif'].join(','),
        fontWeight: themeProps.fontWeight.bold,
        lineHeight: themeProps.lineHeight.base,
        color: textColor,
      },
      body1: {
        fontFamily: [themeProps.fontFamily.body, 'sans-serif'].join(','),
        fontWeight: themeProps.fontWeight.normal,
        lineHeight: themeProps.lineHeight.base,
        fontSize: themeProps.textSize.default,
        color: textColor,
      },
      body2: {
        fontFamily: [themeProps.fontFamily.body, 'sans-serif'].join(','),
        fontWeight: themeProps.fontWeight.normal,
        lineHeight: themeProps.lineHeight.base,
        fontSize: themeProps.textSize.small,
        color: textColor,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: darkColor,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            color: mainColor,
            background: textColor,
            textTransform: 'capitalize',
            '&:hover': {
              color: textColor,
              background: darken(mainColor, 0.1),
            },
          },
          outlined: {
            color: textColor,
            background: mainColor,
            textTransform: 'capitalize',
            border: `1px solid ${mode === ThemeMode.dark ? contrastColor : textColor}`,

            '&:hover': {
              color: mainColor,
              background: darken(textColor, 0.1),
            },
          },
          text: {
            color: textColor,
            textTransform: 'capitalize',
            '&:hover': {
              color: mainColor,
              background: darken(textColor, 0.1),
            },
          },
          root: {
            '&.Mui-disabled': {
              color: themeProps.colors.grey,
              background: themeProps.colors.white,
              border: `solid 1px ${contrastColor}`,
            },
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            background: mainColor,
            color: textColor,
            '&:selected': {
              backgroundColor: contrastColor,
            },
            '&.Mui-selected': {
              backgroundColor: contrastColor,
              '&:hover': {
                background: contrastColor,
              },
            },
            '&:hover': {
              background: contrastColor,
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: textColor,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: textColor,
            background: mainColor,
            '&:hover': {
              color: textColor,
              background: lightColor,
            },
            '&.Mui-disabled': {
              color: themeProps.colors.grey,
            },
          },
          colorSecondary: {
            color: mainColor,
            background: textColor,
          },
        },
      },

      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: textColor,
            border: `1px solid ${textColor}`,
            '&.Mui-selected': {
              background: textColor,
              color: mainColor,
              '&:hover': {
                background: textColor,
                color: mainColor,
              },
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            background: mainColor,
            borderRadius: '8px',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: textColor,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: mainColor,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          expandIconWrapper: {
            color: textColor,
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          root: {
            color: textColor,
            '&:after': {
              borderBottom: `solid 1px ${textColor}`,
            },
            '&:before': {
              borderBottom: `solid 1px ${textColor}`,
            },
          },
          input: {
            '&.Mui-disabled': {
              color: textColor,
              WebkitTextFillColor: `${contrastColor} !important`,
            },
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: textColor,
            '&.Mui-disabled': {
              color: textColor,
            },
            '&.Mui-focused': {
              color: textColor,
            },
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: textColor,
            '&.Mui-disabled': {
              color: textColor,
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          icon: {
            color: textColor,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: textColor,
            '&.Mui-checked': {
              color: textColor,
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            color: textColor,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            '&.Mui-active': {
              color: textColor,
            },
            '&.Mui-completed': {
              color: textColor,
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: themeProps.colors.grey,
            '&.Mui-active': {
              color: textColor,
              background: mainColor,
            },
            '&.Mui-completed': {
              color: textColor,
              background: mainColor,
            },
          },
          text: {
            fill: mainColor,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: textColor,
          },
          markLabel: {
            color: textColor,
          },
        },
      },
    },
  });
};
