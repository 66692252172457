import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../styles/images/report/logo.svg';
import leftHeaderBg from '../../styles/images/report/left-header-bg.svg';
import { ReportOutput, VarName } from '../../services/api';
import SummaryStartReport from './SummaryStartReport';
import SummaryEndReport from './SummaryEndReport';
import CO2Report from './CO2Report';
import TempReport from './TempReport';
import HumidityReport from './HumidityReport';
import PmReport from './PmReport';
import UtlReport from './UtlReport';
import useStyles from '../../styles/report';
import { getAvailableLocations, getCurrentLocation, getThemeMode } from '../../state/selectors';
import { getLocationName } from '../../utils/locations';
import whiteLogo from '../../logo_512x512_white.png';
import greenLogo from '../../logo_512x512_teal.png';
import { ThemeMode } from '../../state/types';
import { themeProps } from '../../styles/theme';

interface ReportContentProps {
  reportData: ReportOutput;
  lastMonthData: ReportOutput;
  closeReport: () => void;
}

function ReportContent({
  reportData,
  lastMonthData,
  closeReport,
}: ReportContentProps): JSX.Element {
  const classes = useStyles();
  const currentLocation = useSelector(getCurrentLocation);
  const allLocations = useSelector(getAvailableLocations);
  const themeMode = useSelector(getThemeMode);
  const { bands, utl, period } = reportData;

  // co2 data
  const co2Data = bands.find((data) => data.varName === VarName.Co2ppm);
  const lastMonthCo2DesiredPct = lastMonthData.bands.find((data) => data.varName === VarName.Co2ppm)
    ?.summary.desiredPct;

  // temperature data
  const tempData = bands.find((data) => data.varName === VarName.TemperatureC);
  const lastMonthTempDesiredPct = lastMonthData.bands.find(
    (data) => data.varName === VarName.TemperatureC
  )?.summary.desiredPct;

  // humidity data
  const humData = bands.find((data) => data.varName === VarName.RelativeHumidity);
  const lastMonthHumDesiredPct = lastMonthData.bands.find(
    (data) => data.varName === VarName.RelativeHumidity
  )?.summary.desiredPct;

  // particulate matter data
  const pmData = bands.find((data) => data.varName === VarName.ParticulateMatter);
  const lastMonthPmDesiredPct = lastMonthData.bands.find(
    (data) => data.varName === VarName.ParticulateMatter
  )?.summary.desiredPct;

  // utilisation data
  const utlData = utl.find((data) => data.varName === VarName.ClientsWiFi);
  const lastMonthUtlData = lastMonthData.utl.find((data) => data.varName === VarName.ClientsWiFi);

  return (
    <div className={`${classes.reportContent} print`}>
      <AppBar className={`${classes.header} no-print`}>
        <Toolbar>
          <img
            src={themeMode === ThemeMode.dark ? whiteLogo : greenLogo}
            alt="logo"
            height="35"
            width="35"
          />
          <div style={{ flex: '1 1 auto' }} />
          <Button
            onClick={() => window.print()}
            className={classes.headerButton}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
          <Button onClick={closeReport} className={classes.headerButton} startIcon={<CloseIcon />}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <main className={`${classes.main} ${classes.reportContainer}`}>
        <div className={classes.reportHeader}>
          <div style={{ alignSelf: 'center' }}>
            <img src={leftHeaderBg} alt="Left Header Background" />
            <Typography className={classes.headerText}>
              {dayjs(period.start).format('MMMM YYYY')} for <br />{' '}
              {getLocationName(allLocations, currentLocation)}
            </Typography>
          </div>

          <div style={{ alignSelf: 'center', marginRight: '15px' }}>
            <img src={logo} alt="logo" />
          </div>
        </div>

        <SummaryStartReport reportData={reportData} lastMonthData={lastMonthData} />
        {co2Data && <CO2Report co2Data={co2Data} lastMonthDesiredPct={lastMonthCo2DesiredPct} />}
        {tempData && (
          <TempReport tempData={tempData} lastMonthDesiredPct={lastMonthTempDesiredPct} />
        )}
        {humData && (
          <HumidityReport humData={humData} lastMonthDesiredPct={lastMonthHumDesiredPct} />
        )}
        {pmData && <PmReport pmData={pmData} lastMonthDesiredPct={lastMonthPmDesiredPct} />}
        {utlData && <UtlReport utlData={utlData} lastMonthUtlData={lastMonthUtlData} />}
        <SummaryEndReport reportData={reportData} />
        <div className={classes.reportFooter}>
          <div style={{ alignSelf: 'center', marginLeft: '15px' }}>
            <img src={logo} alt="logo" />
          </div>
          <Typography style={{ alignSelf: 'center', color: themeProps.colors.grey }}>
            LightFi Limited is registered in England and Wales with Company Registration <br /> No
            10212060, at 27 Old Gloucester Street, London, WC1N 3AX, UK
          </Typography>
          <div />
        </div>
      </main>
    </div>
  );
}

export default ReportContent;
