import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

import posthog from 'posthog-js';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';
import {
  SensorHistoryPlotItem,
  SensorMetricPlotItem,
  isHistoryData,
  isMetricData,
} from '../Plots/plotCommon';
import useStyles from '../../styles';
import { themeProps } from '../../styles/theme';
import { prepareCSVzip, prepareMetricCSV } from '../../utils/dataDownload';
import {
  getActivePlotVars,
  getCurrentBuildingProps,
  getCurrentLocationProps,
  getGhostParams,
  getSelectedEndDate,
  getSelectedHours,
  getSelectedSensorIds,
  getSelectedStartDate,
  getShowAdvancedAnalysis,
} from '../../state/selectors';
import Report, { statsPerVarNamePerSensor } from '../../components/ComparePageReport';
import StatsBox from './StatsBox';
import { setGhostParams, toggleShowAdvancedAnalysis } from '../../state/actions';

interface CompareReportProps {
  sensorData: SensorHistoryPlotItem[] | SensorMetricPlotItem[];
  fetchingSensorData: boolean;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />
);

function CompareReport({ sensorData, fetchingSensorData }: CompareReportProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [reportOpen, setReportOpen] = useState(false);
  const selectedSensors = useSelector(getSelectedSensorIds);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const location = useSelector(getCurrentLocationProps);
  const building = useSelector(getCurrentBuildingProps);
  const selectedVarNames = useSelector(getActivePlotVars);
  const selectedHours = useSelector(getSelectedHours);
  const ghostParams = useSelector(getGhostParams);
  const showAdvancedAnalysis = useSelector(getShowAdvancedAnalysis);
  const { addGhosts, weekCount } = ghostParams;

  // Selected hours only if option is selected
  const { selectHours } = selectedHours;
  let { startHour, endHour, includeWeekends } = selectedHours;
  if (!selectHours) {
    startHour = 0;
    endHour = 24;
    includeWeekends = true;
  }

  const prepareReport = () => {
    setReportOpen(true);
  };

  const prepareDownload = () => {
    if (isHistoryData(sensorData)) {
      prepareCSVzip(sensorData);
      posthog.capture('CSV downloaded from compare page');
    }
    if (isMetricData(sensorData)) {
      prepareMetricCSV(sensorData, selectedVarNames, ghostParams, selectedHours);
      posthog.capture('Metric CSV downloaded from compare page');
    }
  };

  const handleClose = () => {
    setReportOpen(false);
  };

  const locationName = useMemo(() => {
    let name = building?.name ?? '';
    const floorName = location?.raw?.name ?? '';

    if (name.length > 0 && floorName.length > 0) {
      name += ' ';
    }
    name += floorName;

    if (name.length === 0) {
      name = 'Unknown location';
    }

    return name;
  }, [location, building]);

  const stats = useMemo(
    () => statsPerVarNamePerSensor(sensorData, [startHour, endHour], includeWeekends),
    [sensorData, startHour, endHour, includeWeekends]
  );

  const handleGhostOffset = (offset: number) =>
    dispatch(setGhostParams({ ...ghostParams, weekCount: offset }));

  const ghostDateLabel = useMemo(() => {
    let label = '';
    const ghostTimeOffsetVal = weekCount * 7 * 24 * 3600;
    const ghostStart = new Date(startDate).getTime() - ghostTimeOffsetVal * 1000;
    const ghostEnd = new Date(endDate).getTime() - ghostTimeOffsetVal * 1000;
    if (ghostStart && ghostEnd)
      label = `${dayjs(ghostStart).format('ll')}
    - ${dayjs(ghostEnd).format('ll')} `;
    return label;
  }, [endDate, startDate, weekCount]);

  return (
    <Box>
      <Accordion
        style={{
          width: '100%',
          margin: '1rem 0 1.5rem 0',
          background: theme.palette.primary.light,
        }}
        expanded={showAdvancedAnalysis}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => dispatch(toggleShowAdvancedAnalysis(!showAdvancedAnalysis))}
            />
          }
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
              <AssignmentIcon style={{ alignSelf: 'center' }} />
              <Typography variant="h6" style={{ alignSelf: 'center', marginLeft: '10px' }}>
                Advanced Analysis
                <Typography variant="body2">
                  {`${dayjs(startDate).format('ll')}
            - ${dayjs(endDate).format('ll')} `}
                </Typography>
              </Typography>
            </Box>

            {showAdvancedAnalysis && (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ margin: { xs: '0 0 0 20px', lg: ' 0 20px' } }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addGhosts}
                        onChange={() =>
                          dispatch(setGhostParams({ ...ghostParams, addGhosts: !addGhosts }))
                        }
                        style={{ padding: '0 9px' }}
                      />
                    }
                    label="Show Ghosts"
                  />
                  <Typography variant="body2">{ghostDateLabel}</Typography>
                </Box>
                <TextField
                  type="number"
                  label="No. of Weeks"
                  style={{ textTransform: 'capitalize', width: '100px' }}
                  value={weekCount}
                  onChange={(e) => handleGhostOffset(Number(e.target.value))}
                  variant="standard"
                  size="small"
                  disabled={!addGhosts}
                />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <Box sx={{ padding: { lg: '1rem', xs: '0.5rem' } }}>
          <StatsBox stats={stats} />
          <FormGroup row className={classes.reportToolbar}>
            <Button
              variant={themeProps.btnVariant.default}
              onClick={prepareReport}
              startIcon={<AssignmentIcon>prepare report</AssignmentIcon>}
              className={classes.reportButton}
            >
              View Report
            </Button>
            <Tooltip title="Download data in .csv format">
              <span>
                <Button
                  variant={themeProps.btnVariant.default}
                  onClick={prepareDownload}
                  startIcon={<SaveAltIcon />}
                  className={classes.reportButton}
                  disabled={selectedSensors.length === 0}
                >
                  Download CSV
                </Button>
              </span>
            </Tooltip>
          </FormGroup>
        </Box>
      </Accordion>
      <Dialog
        fullScreen
        open={reportOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ zIndex: 10000 }}
      >
        <Report
          stats={stats}
          location={locationName}
          dateRange={[startDate, endDate]}
          selectedHours={[startHour, endHour]}
          includeWeekends={includeWeekends}
          onClose={handleClose}
          fetchingSensorData={fetchingSensorData}
        />
      </Dialog>
    </Box>
  );
}

export default CompareReport;
